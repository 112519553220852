export const Companies = [
  {
    role: 'SENIOR FULL STACK WEB DEVELOPER',
    description: [
      'Successfully developed a tailored ERP solution to meet the unique needs of clients or the organization',
      'Proficient in integrating ERP systems with other essential business software, ensuring seamless data flow and process automation.',
      'Optimized ERP systems for scalability and efficiency, ensuring responsive performance even with substantial growth.',
    ],
    date: 'Aug 2023 - Present',
    name: 'Education Incubation LLC , USA',
  },
  {
    role: 'SENIOR FULL STACK WEB DEVELOPER',
    description: [
      'Developed elegant website for the the client with CMC.',
      'Developed CRM SAAS project.',
      'Mentoring junior web developers, and providing technical support through code reviews.',
    ],
    date: 'Aug 2022 - Jan 2023',
    name: 'GREEN PROFFS',
  },
  {
    role: 'SENIOR BACK WEB DEVELOPER',
    description: [
      'Integrated existing software into upgraded, modified systems for higher performance.',
      'Developing new module for there system / integrating payment.',
      'Participated in continuous improvement by generating suggestions, engaging in problem-solving activities to support teamwork.',
    ],
    date: 'Jan 2022 - May 2023',
    name: 'PLUC',
  },
  {
    role: 'FULL STACK WEB DEVELOPER',
    description: [
      'Developed 15+ software solutions through systematic analysis and creative problem-solving.',
      'Managed 200+ short-term sprints and 50+ long-term deployments for efficient project delivery.',
      'Worked collaboratively with back-end developers to design APIs, conducting software and hardware interface evaluations, and performing thorough code reviews. ',
    ],
    date: 'Jan 2021 - Nov 2022',
    name: 'REZOSYSTEM',
  },
  {
    role: 'SENIOR FULL STACK WEB DEVELOPER',
    description: [
      'Coordinated large-scale software deployments efficiently, ensuring that the projects were completed on time and within budget.',
      'Consulted with engineering team members to determine system loads and develop improvement plans',
      'Demonstrated leadership skills as a senior developer, collaborating with junior team members to achieve shared objectives.',
    ],
    date: 'Nov 2019 - Aug 2022',
    name: 'Zowi Tech',
  },
];
