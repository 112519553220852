export const featuredProjects = [
  {
    title: 'Loyalty',
    description:
      'Shoa supermarket chain has successfully introduced its loyalty program in more than 17 branches, effectively reaching a large customer and user base. As a result of this initiative, the company has achieved an impressive milestone by generating an approximate sum of over 40+ million ETB within just one month since its launch.',
    stack: ['Vue.js', 'Laravel', 'AWS', 'NGINX'],
    link: '',
  },
  {
    title: 'Shufere',
    description:
      "The objective of this project was to create a web application that would allow dispatchers and system administrators to control their taxi fleet from a single platform, providing advanced features such as managing drivers, assigning tasks, and real-time monitoring of the taxi fleet's status.",
    stack: ['Vue.js', 'Tailwind CSS'],
    link: '',
  },
  {
    title: 'Green Proffs',
    description:
      'As a full stack web developer at Green Professional Service, I was responsible for developing their entire website, which included a CMS. Additionally, I worked on the CRM and other web development projects.',
    stack: ['Vue.js', 'Laravel', 'Nuxt js', 'MySQL', 'Tailwind.css'],
    link: 'https://www.greenproffs.com/',
  },
  {
    title: 'Madhanite',
    description:
      'I created a comprehensive system which consisted of a web app for the admin dashboard, mobile app for both passengers and drivers, and integrated the Google Maps API. As the developer, I was responsible for developing and designing the admin dashboard with elegant UI components. After development was complete, I deployed the project on VPS servers and integrated all necessary APIs on the frontend to ensure successful functionality.',
    stack: ['Vue.js', 'Laravel', 'Docker'],
    link: 'https://www.medhanite.com/',
  },
  {
    title: 'GETZ',
    description:
      'GETZ is an Ethiopia-based digital platform that explores the ins and outs of the Ethiopian creative and cultural scenes. With a quarterly online publication and a steady stream of insightful and entertaining monthly digital content, GETZ functions to highlight creative practitioners and set a standard to raise the profile of the creative industry of Ethiopia and Africa.',
    stack: ['Vue.js', 'Laravel', 'Nuxt.js'],
    link: 'https://www.getzmag.com/',
  },
   {
    title: 'Crypto Tracker',
    description:
      "A cutting-edge web application centered around cryptocurrency tracking, developed using Vue.js and Laravel Inertia. The platform provides users with real-time data on various cryptocurrencies through interactive graphs and charts. My role involved integrating external APIs from third-party sources to fetch and display dynamic information, enhancing the application's functionality.",
    stack: ['Vue.js', 'Laravel', 'Inertia.js'],
  },
];

export const otherProjects = [
  {
    title: 'Event Booking',
    description:
      "Developed an event management system for People Like Us Create (PLUC), India's largest mobile storytelling platform, as a back-end developer. Managed event creation, ticket sales, and transaction reports with third-party platforms such as Stripe cashier. Integrated all necessary APIs and ensured successful purchase notifications, report generation, and QR code ticket generation.",
    stack: ['Laravel', 'MySQL', 'AWS', 'NGINX'],
    link: '',
  },
  {
    title: 'Shuufare',
    description:
      'I developed a comprehensive transportation system that includes a web app for admin dashboard, a mobile app for passengers, and a driver. My primary role was to create an admin dashboard with elegant UI components using Vue.js. I integrated the Google maps API on the frontend and deployed the project on VPS servers using Docker. Git and GitHub were used for version control to ensure proper tracking of progress and facilitate collaboration. The resulting system is user-friendly and efficient.',
    stack: ['Vue.js', 'Laravel', 'MySQL', 'Docker', 'Vuex', 'Tailwind.css'],
    link: '',
  },
  {
    title: 'European Union',
    description:
      "The objective of this project was to devise and monitor a plan's progress throughout the year. As a full-stack developer, I was accountable for the system's frontend and backend development. The system featured an interactive Google Maps-based map displaying projects throughout 9 national regions, along with statistical data and reports on regions, project creation and management, budget tracking, and the number of households that benefited from the projects.",
    stack: ['Vue.js', 'Laravel', 'MySQL', 'Docker', 'AWS', 'NGINX'],
    link: '',
  },
];
